














































































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import User from '@improve/common-utils/src/model/User';
import Team from '@improve/common-utils/src/model/Team';
import BaseButton from '@improve/common-components/src/components/widgets/BaseButton.vue';

@Component({
  name: 'AboutTab',
  components: {
    BaseButton
  }
})
export default class AboutTab extends Vue {
  @Getter currentUser?: User;

  @Getter currentUserUnit?: Team;

  get unitName(): string {
    return this.currentUserUnit?.name || '-';
  }
}
